$font-family-main: "Baloo Da 2", cursive;
$font-family-secondary: "Titillium Web", sans-serif;
$font-color-main: #333;
$font-color-secondary: #eee;
$link-color: #275ad1;
$background-color-main: #666;
$nav-background: #222;
$scroll-to-top: #262161;
$box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
$box-shadow-elegant: 1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15);
$box-shadow-inset: inset 1px 3px 7px
  lighten(
    $color: $font-color-main,
    $amount: 40%,
  );
$container-width: 1240px;
$border-radius-main: 5px;
$font-weight-main: 600;
$font-size-main: 16px;

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $font-family-main;
}

#root {
  position: relative;
  min-height: 100vh;
}

body {
  background: #262161 url("../public/assets/images/background.jpg") no-repeat
    fixed center / cover;
  color: $font-color-main;
  overflow-x: hidden;
  transition: background 0.7s ease-out;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: $font-color-secondary;
  }
  ::-webkit-scrollbar-thumb {
    background: $background-color-main;
    border: 1px solid $font-color-main;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: darken($color: $background-color-main, $amount: 10%);
  }
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  .hidden {
    display: none;
  }
  .no-posts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: clamp(20px, 5vw, 40px);
    color: $font-color-secondary;
  }
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 15px 20px;
    background-color: $nav-background;
    box-shadow: $box-shadow;
    z-index: 1;
    .nav-wrapper {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 15px;
      width: 100%;
      max-width: calc($container-width - 40px);
      ul.nav-container {
        width: 100%;
        display: flex;
        gap: 20px;
        list-style: none;
        li.nav-link {
          display: flex;
          &:nth-child(4) {
            flex: 1;
          }
          a {
            display: inline-flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            font-weight: $font-weight-main;
            color: $font-color-secondary;
            text-decoration: none;
            transition: 0.2s ease-in-out;
            .material-symbols-outlined {
              font-size: inherit;
            }
            &:hover {
              color: #d47ac0;
            }
          }
        }
      }
      .search-container {
        position: relative;
        width: 100%;
        max-width: 220px;
        label {
          display: none;
        }
        .search-input {
          width: 100%;
          padding: 4px 12px;
          font-size: 16px;
          color: $font-color-secondary;
          outline: none;
          border: 1px solid $font-color-main;
          border-radius: 4px;
          background-color: $font-color-main;
          transition: background-color 0.3s;
          &::placeholder {
            transition: 0.5s;
          }
          &:focus {
            background-color: lighten($color: $font-color-main, $amount: 5%);
            border-color: $background-color-main;
            outline: none;
            &::placeholder {
              color: transparent;
              transform: translateX(10px);
            }
          }
        }
        .search-results {
          position: absolute;
          top: 50px;
          right: 0;
          padding-top: 8px;
          width: calc(100vw - 40px);
          max-width: max-content;
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          background-color: $nav-background;
          border-radius: 0 0 $border-radius-main $border-radius-main;
          .no-results {
            padding: 6px 12px;
            font-size: 14px;
            color: $font-color-secondary;
          }
          .search-result-item {
            border-bottom: 1px solid $font-color-main;
            font-size: 14px;
            padding: 3px;
            a {
              display: block;
              padding: 8px 10px;
              color: lighten($color: $link-color, $amount: 30%);
              line-height: 1;
              text-decoration: none;
              transition: 0.3s;
              &:hover {
                color: #eee;
                background-color: darken($color: $nav-background, $amount: 3%);
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .container {
    max-width: $container-width;
    min-height: calc(100vh - 140px);
    margin: 0 auto;
    padding: 30px 20px 100px;

    &.home-grid {
      display: grid;
      grid-template-columns: 1fr 300px;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      aside {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    &.login-register {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;

      .title {
        margin-bottom: 15px;
        font-size: 32px;
        font-weight: 600;
        line-height: 1;
        color: $font-color-secondary;
        text-shadow: 1px 1px 2px $font-color-main;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 100%;
        max-width: 360px;
        padding: 20px 20px 25px;
        border-radius: $border-radius-main;
        background-color: #33333390;
        box-shadow: $box-shadow-elegant;

        .visually-hidden {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          white-space: nowrap;
          word-wrap: normal;
        }

        .input {
          padding: 8px 12px;

          &:focus {
            outline: none;
            border-color: $font-color-main;
          }

          &::placeholder {
            color: $background-color-main;
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.3;
        text-align: center;
        text-shadow: 1px 1px 1px #555;
        color: #fff;
        a {
          color: #fff;
          font-weight: 600;
          text-decoration: underline;
          text-transform: uppercase;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .error {
        margin-top: 16px;
        padding: 0 20px;
        color: #fff;
        font-weight: 600;
        font-size: clamp(14px, 4vw, 20px);
        line-height: 1.3;
      }
    }

    .share-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin: 20px 0;

      .share-button {
        flex: 1;
        display: inline-block;
        padding: 12px 20px;
        border: none;
        border-radius: 5px;
        font-weight: 500;
        font-size: clamp(14px, 3vw, 16px);
        line-height: 1;
        text-align: center;
        text-decoration: none;
        border: 2px solid $font-color-secondary;
        box-shadow: $box-shadow-elegant;
        transition: all 0.3s ease-out;

        &:hover {
          border-color: #ccc;
        }

        &.twitter {
          background-color: #1da1f2;
          color: white;

          &:hover {
            background-color: darken(#1da1f2, 10%);
          }
        }

        &.facebook {
          background-color: #1877f2;
          color: white;

          &:hover {
            background-color: darken(#1877f2, 10%);
          }
        }

        &.linkedin {
          background-color: #0a66c2;
          color: white;

          &:hover {
            background-color: darken(#0a66c2, 10%);
          }
        }

        &.whatsapp {
          background-color: #25d366;
          color: white;

          &:hover {
            background-color: darken(#25d366, 10%);
          }
        }
      }
    }

    .projects-wrapper {
      @media (max-width: 640px) {
        margin-top: 20px;
      }
      .categories-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        @media (max-width: 640px) {
          flex-direction: column;
          align-items: center;
        }
        h2 {
          margin: 0;
          font-size: clamp(28px, 6vw, 32px);
          font-weight: bold;
          line-height: 1;
        }
      }
      .categories {
        display: flex;
        gap: 10px;
        @media (max-width: 640px) {
          width: 100%;
          flex-wrap: row wrap;
          margin-top: 10px;
          > * {
            flex: 1;
          }
        }
      }
      &__boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        list-style: none;
        margin-bottom: 15px;
        @media (max-width: 980px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 640px) {
          grid-template-columns: 1fr;
        }
      }

      &__box {
        padding: 20px 20px 25px;
        background-color: #f0f0f0dd;
        border: 3px solid #fff;
        border-radius: 8px;
        box-shadow: $box-shadow;

        &:hover img {
          opacity: 1;
        }

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          margin-bottom: 10px;
          min-height: 2.8rem;
          line-height: 1;
          text-align: center;
          text-wrap: balance;
        }

        img {
          opacity: 0.9;
          width: 100%;
          height: auto;
          aspect-ratio: 1;
          background-color: rgb(203, 203, 203);
          border: 1px solid #fff;
          border-radius: $border-radius-main;
          transition: all 0.2s ease-in;
          box-shadow: $box-shadow-elegant;
        }

        p {
          font-size: 1rem;
          color: $font-color-main;
        }

        .project-links {
          margin-top: 10px;
          display: flex;
          gap: 8px;
          > * {
            flex: 1;
            font-size: 18px;
            padding: 10px 15px;
          }
        }
      }
    }

    &.saved-posts-wrapper .saved-posts {
      &__header {
        font-size: 2em;
        color: $font-color-secondary;
        text-shadow: 1px 1px 1px #8b1b58;
        margin-bottom: 10px;
      }

      &__summary {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 12px 18px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.4;
        color: $font-color-main;
        text-wrap: balance;
        background-color: #fff;
        border-radius: $border-radius-main;
        box-shadow: $box-shadow;
        button {
          width: max-content;
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 7px;
        > span {
          color: $font-color-secondary;
        }
      }

      &__item {
        padding: 8px 12px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffffdb;
        border-radius: $border-radius-main;
        box-shadow: $box-shadow-elegant;
        &:nth-child(odd) {
          background-color: #feebf8db;
        }
        button {
          margin-bottom: 3px;
          .material-symbols-outlined {
            font-size: 16px;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .published {
          color: $font-color-main;
          font-size: 13px;
        }
        a {
          font-weight: 600;
          line-height: 1.2;
          text-decoration: none;
          text-wrap: balance;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &__empty-message {
        display: flex;
        flex-direction: column;
        color: $font-color-secondary;
        font-size: 18px;
        line-height: 1.4;
      }
    }
  }

  h1.post-page-title {
    position: relative;
    width: 100%;
    padding: 25px 40px;
    margin-bottom: -5px;
    font-size: clamp(22px, 5vw, 36px);
    font-weight: 500;
    line-height: 1.2;
    color: $font-color-secondary;
    background-color: lighten($color: $nav-background, $amount: 10%);
    border-radius: $border-radius-main $border-radius-main 0 0;
    box-shadow: $box-shadow;
    text-align: center;
    text-wrap: balance;
    text-shadow: 1px 1px 1px #111;
    transition: opacity 0.3s ease-in-out;
    .bookmark {
      position: absolute;
      top: 5px;
      left: 5px;
      color: darken($color: $font-color-secondary, $amount: 20%);
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        color: $font-color-secondary;
      }
      .material-symbols-outlined {
        font-size: clamp(20px, 4vw, 30px);
        display: block;
        &.added {
          color: yellowgreen;
        }
      }
    }
    .notification {
      position: absolute;
      top: 40px;
      left: 10px;
      padding: 10px 20px;
      font-size: clamp(14px, 3vw, 16px);
      background-color: lighten($color: $font-color-main, $amount: 10%);
      box-shadow: $box-shadow;
      border: 1px solid $background-color-main;
      border-radius: $border-radius-main;
    }
    .title-updated {
      position: absolute;
      bottom: -8px;
      left: 50%;
      padding: 5px 12px;
      font-size: 11px;
      font-weight: 400;
      color: $font-color-secondary;
      line-height: 1;
      background-color: lighten($color: $nav-background, $amount: 10%);
      border-radius: $border-radius-main;
      transform: translateX(-50%);
      text-shadow: none;
    }
    .narrator {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #ddd;
    }
    .narration-wrapper {
      opacity: 0.5;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      margin: 10px auto 0;
      max-width: 340px;
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
      button,
      .voice-select-wrapper {
        flex: 1;
      }
      .voice-select-wrapper {
        display: contents;
        .voice-dropdown {
          padding: 2px 10px;
          width: 100%;
        }
      }
    }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
  h2.heading {
    margin-bottom: 15px;
    font-weight: $font-weight-main;
    color: $font-color-secondary;
    text-shadow: 1px 1px 1px $nav-background;
  }
  ul.category-links {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    margin-bottom: 10px;
    font-weight: 600;
    padding: 0;
    list-style-type: none;
    li {
      flex: 1;
      a {
        display: block;
        text-align: center;
        padding: 5px 10px;
        font-size: 14px;
        color: $font-color-main;
        text-decoration: none;
        text-wrap: nowrap;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: $border-radius-main;
        box-shadow: $box-shadow;
        transition: 0.3s;
        text-transform: capitalize;

        &.active {
          color: #fff;
          background-color: #7f387a;
          border-color: $font-color-secondary;
        }

        &.inactive {
          &:hover {
            background-color: #fdffc8;
          }
        }
      }
    }
  }
  form {
    padding: 20px;
    font-size: $font-size-main;
    background-color: #eee;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow;
    .title-category-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .title-wrapper {
        flex: 1;
      }
      .category-wrapper {
        flex: 1;
        select {
          font-size: 15px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
          background-position: calc(100% - 20px) calc(1em + 6px),
            calc(100% - 15px) calc(1em + 6px), calc(100% - 2.5em) 0.8em;
          background-size: 5px 5px, 5px 5px, 1px 1.5em;
          background-repeat: no-repeat;

          &:focus {
            background-image: linear-gradient(
                45deg,
                $font-color-main 50%,
                transparent 50%
              ),
              linear-gradient(135deg, transparent 50%, $font-color-main 50%),
              linear-gradient(to right, #ccc, #ccc);
            background-position: calc(100% - 15px) calc(1em + 6px),
              calc(100% - 20px) calc(1em + 6px), calc(100% - 2.5em) 0.8em;
            background-size: 5px 5px, 5px 5px, 1px 1.5em;
            background-repeat: no-repeat;
            border-color: green;
            outline: 0;
          }
          &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
          }
        }
      }
    }
    .toolbar {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      margin: 10px 0 5px;
      button {
        flex: 1;
        padding: 5px 10px;
        font-size: 14px;
        color: $font-color-main;
        background-color: #fff;
        border: none;
        border-radius: $border-radius-main;
        transition: 0.3s;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
        &:hover {
          background-color: #fcffc1 !important;
        }
      }
    }
    label {
      font-weight: $font-weight-main;
    }
    input,
    textarea,
    select,
    .DraftEditor-root {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: $border-radius-main;
      box-sizing: border-box;
      font-size: $font-size-main;
      font-weight: 300;
      background-color: #fff;
      box-shadow: $box-shadow-inset;
      outline: none;
      &:focus-within {
        border-color: lighten($color: $nav-background, $amount: 40%);
      }
      .DraftEditor-editorContainer {
        height: 100%;
        .notranslate.public-DraftEditor-content {
          height: 100%;
        }
      }
    }
    textarea,
    .DraftEditor-root {
      margin-bottom: 0;
      height: 100%;
      resize: vertical;
      transition: 0.3s;
      overflow: auto;
    }
    .error {
      flex: 1;
      margin-bottom: 10px;
      font-weight: 500;
      color: tomato;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .increase-height {
        display: flex;
        gap: 5px;
      }
    }
  }

  .blog-post {
    margin: 0 auto 10px;
    font-weight: 300;
    background: #ffffffdb;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow-elegant;
    .blog-post-body {
      padding: 30px 30px 10px;
      font-size: clamp(16px, 4vw, 20px);
      .blog-post-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        padding-bottom: 15px;
        .date-published {
          padding: 6px 10px;
          font-size: 12px;
          line-height: 1.2;
          color: $font-color-main;
          background: #ffffff;
          border-radius: $border-radius-main;

          .category {
            color: $link-color;
          }
        }

        .edit-post {
          display: flex;
          padding: 3px 4px;
          text-align: center;
          font-size: 14px;
          color: $font-color-main;
          text-decoration: none;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: $border-radius-main;
          transition: 0.3s;

          .material-symbols-outlined {
            font-size: 18px;
            color: $background-color-main;
          }

          &:hover {
            border-color: #aaa;
          }
        }
      }
    }
  }
  .adjacent-posts {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    .prev-post,
    .next-post {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      color: $nav-background;
      font-size: 14px;
      font-weight: $font-weight-main;
      line-height: 1.2;
      text-decoration: none;
      background-color: #fff;
      border-radius: $border-radius-main;
      box-shadow: $box-shadow;
      border: 3px solid #fff;
      transition: 0.3s;
      .next-prev-title {
        color: $font-color-main;
      }
      &:hover {
        color: $font-color-main;
        background-color: #eee;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
  ul.list-posts {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    list-style-type: none;
    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #ffffffdb;
      margin-bottom: 10px;
      border-radius: $border-radius-main;
      border: 3px solid #fff;
      transition: 0.5s;
      box-shadow: $box-shadow-inset, $box-shadow-elegant;
      &:hover {
        background-color: #fcf9da;
        a.post-title {
          &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }
      }
      a.post-title {
        position: relative;
        display: block;
        padding: 8px 5px;
        margin: 5px 5px 0;
        color: $link-color;
        font-size: 18px;
        text-decoration: none;
        transition: 0.3s;
        line-height: 1;
        text-wrap: balance;
        font-weight: 700;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #ccc;
          transform: scaleX(0);
          transform-origin: bottom right;
          transition: transform 0.3s, height 0.1s;
        }
      }
      .post-date {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 1;
        color: lighten($color: $font-color-main, $amount: 20%);
      }
      .post-summary {
        margin-top: 5px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 1.2;
        text-wrap: balance;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .labels-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        padding: 10px;
        background: linear-gradient(0deg, #ffd0ed, transparent);
        border-radius: 0 0 4px 4px;
        .category-label,
        .comments-label {
          padding: 2px 8px;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          text-decoration: none;
          border-radius: $border-radius-main;
        }
        .comments-label {
          display: flex;
          align-items: center;
          background-color: #556ece;
          > i {
            margin-right: 5px;
            font-size: 16px;
          }
        }
        .reading-time {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;
          font-size: 12px;
          line-height: 1;
          color: $font-color-main;
          .material-symbols-outlined {
            font-size: 15px;
          }
        }
      }
    }
  }

  @mixin widgetStyles {
    padding: 20px;
    background-color: rgb($font-color-secondary, 0.8);
    border: 1px solid #fff;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow;
    text-wrap: balance;
    text-align: center;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 20px;
      padding: 10px;
      margin-bottom: 15px;
      text-align: center;
      line-height: 1.3;
      font-weight: 600;
      color: $font-color-secondary;
      background-color: #7f387a;
      border-radius: $border-radius-main;
    }

    .item {
      padding: 15px 10px;
      margin-bottom: 10px;
      border: 1px solid #fff;
      background-color: #fff;
      font-size: 14px;
      line-height: 1.3;
      border-radius: $border-radius-main;
      box-shadow: $box-shadow-elegant;
      transition: 0.3s ease-in-out;

      &:hover {
        border-color: lighten($color: $background-color-main, $amount: 30%);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        font-weight: 600;
        color: $link-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .metadata {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 8px;
        font-size: 0.9em;
        color: #888;
        line-height: 1;

        .date,
        .views {
          display: flex;
          align-items: center;
          gap: 3px;

          .category {
            font-weight: 600;
          }
        }

        .material-symbols-outlined {
          font-size: 14px;
        }
      }
    }
  }

  .latest-comments {
    @include widgetStyles;
    margin-top: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid #fff;
    h2 {
      grid-column: span 2;
      margin-bottom: 5px;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-size: 15px;
      text-align: left;
      .author-content {
        > strong {
          padding: 7px 6px 6px;
          margin-bottom: 10px;
          display: block;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          text-transform: capitalize;
          color: $font-color-main;
          border-radius: $border-radius-main;
          background: linear-gradient(45deg, #ffe7ff, transparent 80%);
        }
      }
      .comment-list__avatar {
        margin-top: 0;
      }
      .metadata {
        align-items: flex-start;
      }
    }
    .show-more-comments {
      grid-column: span 2;
      margin-top: 10px;
    }
  }

  .user-profile,
  .most-viewed-posts,
  .poll-container,
  .post-calendar {
    @include widgetStyles;
  }

  .user-profile {
    &__greetings {
      opacity: 0;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1.3;
      transition: all 0.5s ease;
      transition-delay: 0.3s;
      transform: translateY(-15px);
      &.fade-in {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &__details p {
      display: flex;
      gap: 5px;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 10px;
      }
      strong {
        flex: 0 0 45%;
        font-weight: 600;
        text-align: right;
      }
      span {
        flex: 0 0 55%;
        text-align: left;
        &.role {
          text-transform: capitalize;
        }
      }
    }
    .login-logout {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .btn {
      flex: 1;
      margin: 5px 0;
      font-size: 18px;
    }
  }

  .poll-container {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    .poll-question {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;
      color: $font-color-main;
    }
    .poll-options {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
      .poll-option {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        font-size: 14px;
        line-height: 1.3;
        color: $font-color-main;
        background-color: #fff;
        border: 1px solid #bbb;
        border-radius: $border-radius-main;
        box-shadow: $box-shadow-elegant;
        transition: 0.3s;
        z-index: 1;
        .percentage-background {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          background-color: rgb(138 25 146 / 50%);
          z-index: -1;
        }
        &:hover {
          border-color: #d47ac0;
        }
        &.selected {
          border-color: $nav-background;
          background-color: $nav-background;
          color: #fff;
        }
      }
    }
    .vote-button {
      width: 100%;
    }
    .voted-message {
      padding: 5px 10px 0;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 600;
      color: lighten($color: $font-color-main, $amount: 15%);
    }
  }

  .post-calendar {
    .month-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      color: $font-color-main;
      font-weight: 600;
      .month-nav {
        padding: 2px 10px;
        font-size: 14px;
        color: $font-color-main;
        background-color: #fff;
        border: 1px solid #aaa;
        border-radius: $border-radius-main;
        transition: 0.3s;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &:hover {
          border-color: $font-color-main;
        }
      }
    }
    .day-names {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
      font-size: 12px;
      color: lighten($color: $font-color-main, $amount: 15%);
      font-weight: 600;
      span {
        flex-basis: 13.6%;
        text-align: center;
        &:nth-child(6),
        &:nth-child(7) {
          color: rgb(190, 71, 137);
        }
      }
    }
    .days-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2px;

      span {
        flex-basis: 13.6%;
        padding: 5px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        color: $font-color-main;
        background-color: #fff;
        transition: 0.3s;
        border: 1px solid #d779cd;
        border-radius: 3px;
        &:hover:not(.future, .padding-day) {
          border-color: #a03996;
        }
      }
      .day {
        cursor: pointer;
        color: #d35cc7;
        font-weight: 600;
        background: linear-gradient(0deg, #f7d6fa, #fff);
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        cursor: default;
      }
      .today {
        background-color: rgba(247, 133, 230, 0.2);
      }
      .future,
      .padding-day {
        opacity: 0.6;
        color: #aaa;
        border-color: #ccc;
      }
    }
  }
  .modal-posts-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: max-content;
    max-height: calc(100vh - 180px);
    width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    h3 {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 600;
      color: $font-color-main;
      line-height: 1.2;
    }
    .post-info {
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      a.post-title {
        display: block;
        font-size: 14px;
        color: $font-color-main;
        line-height: 1.2;
        text-decoration: none;
        transition: 0.3s;
        font-weight: 600;
        text-align: left;
        &:hover {
          color: $link-color;
          text-decoration: underline;
        }
      }
      .post-date {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: #777;
        .material-symbols-outlined {
          font-size: 14px;
        }
        .category {
          text-decoration: none;
          font-weight: 500;
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 0 24px;
  font-weight: 600;
  font-size: 16px;

  button,
  span.page-number {
    padding: 10px 15px;
    line-height: 1;
    box-shadow: $box-shadow-elegant;
    border: 1px solid #fff;
    border-radius: $border-radius-main;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  button {
    display: flex;
    align-items: center;
    .material-symbols-outlined {
      font-size: 16px;
      font-weight: 600;
    }

    &:hover {
      background-color: #e0e0e0;
    }

    &:disabled {
      background-color: #ddd;
      cursor: default;
    }
  }

  span.page-number {
    &.active-page {
      background-color: #7f387a;
      color: #fff;
      cursor: default;
      box-shadow: none;
    }

    &:hover:not(.active-page) {
      background-color: #ccc;
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .spinner {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
    .path {
      stroke: #9e3a82;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.footer-container {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width: 100%;
  min-height: 70px;
  background: linear-gradient(0deg, #0d0d0d90, #1a1a1a90, #4b0b3860);
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    span {
      font-size: 14px;
      color: #ccc;
      .author {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        .material-symbols-outlined {
          font-size: 13px;
          color: tomato;
        }
        a {
          color: bisque;
          text-decoration: none;
        }
      }
    }
  }
}

.blog-post-body,
.DraftEditor-root {
  .public-DraftStyleDefault-h1 {
    font-size: 2em;
    font-weight: bold;
  }

  .public-DraftStyleDefault-h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  .public-DraftStyleDefault-h3 {
    font-size: 1.17em;
    font-weight: bold;
  }
  h1,
  h2,
  h3 {
    margin: 12px 0 6px;
    line-height: 1.1;
    font-weight: bold;
    color: #b14a9e;
  }
  h1 {
    font-size: 1.6em;
  }
  h2 {
    font-size: 1.45em;
  }
  h3 {
    font-size: 1.3em;
  }
  blockquote {
    margin: 10px;
    padding: 10px 15px;
    border-left: 5px solid #ef91f3;
    background-color: #f7f7f7;
    font-style: italic;
    border-radius: 0 $border-radius-main $border-radius-main 0;
  }
  ul,
  ol {
    padding-left: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  ul {
    list-style-type: circle;
  }

  ul li {
    margin-bottom: 0.25em;
  }

  ol {
    list-style-type: decimal;
  }

  ol li {
    margin-bottom: 0.25em;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

$btn-colors: (
  "red": (
    #e74c3c,
    #ce3323,
  ),
  "green": (
    #2ecc71,
    #15b358,
  ),
  "blue": (
    #55acee,
    #3c93d5,
  ),
  "yellow": (
    #f1c40f,
    #d8ab00,
  ),
  "purple": (
    #b022b4,
    #82257f,
  ),
  "purple-light": (
    #e050e4,
    #ab43a7,
  ),
  "light-gray": (
    #a9a9a9,
    #7f7f7f,
  ),
  "dark-gray": (
    #696969,
    #505050,
  ),
  "invisible": (
    rgb(255, 255, 255, 0.2),
    rgb(255, 255, 255, 0.4),
  ),
);

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px 10px;
  font-size: clamp(14px, 3vw, 18px);
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  outline: none;
  text-shadow: 0 1px 1px #777;
  transition: 0.2s;
  cursor: pointer;

  @each $color, $values in $btn-colors {
    &.#{$color} {
      background-color: nth($values, 1);
      box-shadow: 0px 5px 0px 0px nth($values, 2);
      border: 1px solid darken(nth($values, 1), 10%);

      &:hover {
        background-color: lighten(nth($values, 1), 5%);
      }
    }
  }

  &:active {
    -webkit-transform: translate(0px, 5px);
    transform: translate(0px, 5px);
    box-shadow: none;
  }

  &.stretch {
    width: 100%;
  }

  &.extra-padding {
    padding: 7px 16px;
    font-size: clamp(20px, 3vw, 24px);
  }

  &.narration-btn {
    display: flex;
    gap: 8px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  .modal-content {
    background-color: #fff;
    padding: 30px 20px;
    margin: 0 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    box-shadow: $box-shadow;
    h2 {
      line-height: 1;
      margin-bottom: 10px;
    }
    p {
      text-wrap: balance;
      line-height: 1.3;
    }
    button.close {
      margin-top: 10px;
    }
  }
}

.scroll-to-top {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 45px;
  height: 40px;
  border-radius: 50% 50% 0 0;
  background-color: lighten($color: $scroll-to-top, $amount: 5%);
  box-shadow: $box-shadow;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: lighten($color: $scroll-to-top, $amount: 10%);
  }
  .material-symbols-outlined {
    padding-bottom: 5px;
    color: darken($color: $font-color-secondary, $amount: 20%);
    transition: 0.3s;
    &:hover {
      color: $font-color-secondary;
    }
  }
}

.comment-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: $border-radius-main;
  background-color: #f9f9f9db;
  box-shadow: $box-shadow;

  &__notification {
    padding: 10px;
    font-size: clamp(13px, 4vw, 16px);
    font-weight: 600;
    line-height: 1.3;
    color: #e74c3c;
    background-color: #f7ffde;
    border: 1px solid #e74c3c;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow-elegant;
  }

  &__title {
    font-size: clamp(18px, 5vw, 24px);
    font-weight: 500;
    text-wrap: balance;
    line-height: 1.2;
  }

  &__logged {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    color: $font-color-main;
    strong {
      font-weight: 600;
    }
  }

  &__input,
  &__textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;

    &::placeholder {
      transition: 0.5s;
    }

    &:focus {
      border-color: $background-color-main;
      outline: none;
      &::placeholder {
        color: transparent;
        transform: translateX(10px);
      }
    }
  }

  &__textarea {
    min-height: 100px;
    resize: vertical;
    box-shadow: $box-shadow-inset;
    &::placeholder {
      font-style: italic;
      color: #aaa;
    }
  }

  &__progress-bar {
    height: 15px;
    font-size: 11px;
    font-weight: 500;
    color: $font-color-secondary;
    border-radius: 3px;
    background-color: #e0e0e0;
    box-shadow: $box-shadow-inset;
    overflow: hidden;
    &__fill {
      position: relative;
      height: 100%;
      background-color: var(--color-green);
      transition: width 0.3s ease-in-out;
      .limit {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 0 5px;
        color: #fff;
        text-shadow: 0 0 1px #333;
        transform: translateY(-50%);
      }
    }
  }

  &__submit {
    width: max-content;
  }
}

.login-to-comment {
  padding: 30px 20px;
  border: 1px solid #ddd;
  border-radius: $border-radius-main;
  background-color: #f9f9f9db;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-wrap: balance;
  line-height: 1;
  .btn {
    margin-top: 20px;
  }
}

.comment-list {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: $border-radius-main;
  color: $font-color-main;
  background-color: #f9f9f9db;
  box-shadow: $box-shadow;

  &__title {
    font-size: clamp(18px, 5vw, 24px);
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 2px $font-color-main;
    padding: 10px;
    background: linear-gradient(0deg, #972388, #ce5a9c);
    border-radius: $border-radius-main;
  }

  &__item {
    position: relative;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #fff;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow-elegant;
    text-wrap: balance;
    &:hover button {
      opacity: 1;
    }
  }

  &__avatar {
    vertical-align: middle;
    margin-top: 5px;
    margin-right: 5px;
    width: 32px;
    height: 32px;
    border-radius: $border-radius-main;
    border: 2px solid #fff;
    box-shadow: 0 0px 3px #c5c5c5;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius-main;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    border-radius: $border-radius-main;
    text-transform: capitalize;
    &-label {
      position: absolute;
      top: 0;
      left: 10px;
      padding: 3px 5px;
      font-size: 10px;
      font-weight: 500;
      color: $font-color-secondary;
      background-color: #a32f9b;
      border: 2px solid #fff;
      border-radius: $border-radius-main;
      transform: translateY(-50%);
      box-shadow: 0 0px 3px #c5c5c5;
    }
    .author-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      span {
        font-weight: normal;
        font-size: 10px;
      }
    }
  }

  &__content {
    padding: 5px 0 10px;
    font-size: 16px;
    line-height: 1.4;
  }

  &__timestamp {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    margin: 0;
    color: #777;
  }

  &__icon {
    font-size: 14px;
  }

  &__edited {
    margin-left: 5px;
    color: #aaa;
  }

  &__edit {
    display: flex;
    flex-direction: column;
    label {
      display: none;
    }
    textarea {
      flex: 1;
      padding: 5px 8px;
      margin: 10px 0;
      min-height: 100px;
      resize: vertical;
      font-size: inherit;
      outline: none;
      border: 1px solid #40d47e;
    }
  }

  &__buttons {
    display: flex;
    gap: 6px;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    button {
      opacity: 0;
      gap: 5px;
      padding: 4px 6px;
      font-size: 14px;
      .material-symbols-outlined {
        font-size: 18px;
      }
    }
  }
}

.reading-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4px;
  background-color: $nav-background;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  .progress-indicator {
    height: 100%;
    background-color: yellowgreen;
    transition: width 0.3s ease-out;
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 2px;
    color: $font-color-secondary;
    background-color: lighten($color: $font-color-main, $amount: 5%);
    display: flex;
    margin-right: 20px;
    border-radius: $border-radius-main;
    box-shadow: $box-shadow;
    transition: 0.3s;
    &:hover {
      background-color: lighten($color: $font-color-main, $amount: 10%);
    }
    .material-symbols-outlined {
      font-size: 16px;
    }
  }
}

.not-found-wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    text-transform: uppercase;
    font-size: clamp(2rem, 3.5vw, 6rem);
  }
  p {
    color: $font-color-secondary;
    text-align: center;
    font-size: clamp(1rem, 3.5vw, 1.5rem);
    font-weight: 500;
    text-shadow: 1px 1px 1px #333;
  }
}

.cookie-consent {
  position: fixed;
  bottom: 65px;
  left: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 620px;
  justify-content: center;
  gap: 15px;
  background-color: #ffffff;
  box-shadow: $box-shadow;
  border-radius: $border-radius-main;
  z-index: 999999;
  transform: translateX(-50%);
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: $font-color-main;
    text-wrap: balance;
  }
  &.animate-in {
    animation: fadeIn 2s;
  }

  &.animate-out {
    animation: fadeOutMoveDown 1s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOutMoveDown {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate(-50%, 20px);
    }
  }
}

video {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

@media only screen and (max-width: 960px) {
  body {
    header .nav-wrapper ul.nav-container {
      gap: 10px;
      li.nav-link a {
        font-size: 15px;
      }
    }

    .container {
      padding: 10px 20px 100px;
      h1.post-page-title {
        margin-top: 10px;
      }
      &.home-grid {
        display: block;
        .pagination {
          padding: 10px 0 15px;

          button,
          span {
            padding: 7px 12px;
          }

          .material-symbols-outlined {
            padding: 0;
          }
        }
        aside {
          margin-top: 20px;
        }
      }

      form .title-category-wrapper {
        flex-direction: column;
        gap: 0;
      }

      .blog-post {
        width: 100%;

        .blog-post-body {
          padding: 25px;

          .blog-post-footer {
            padding-bottom: 0;
          }
        }
      }
    }

    .footer-container {
      .footer-content {
        span {
          text-wrap: balance;
          line-height: 1.4;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  body {
    ul.nav-container li.nav-link {
      a {
        .material-symbols-outlined {
          font-size: 28px !important;
        }
        .link-text {
          display: none;
        }
      }
    }
    .container {
      ul.category-links {
        margin-top: 10px;
      }
      ul.list-posts,
      .latest-comments {
        display: block;
      }
      .latest-comments {
        margin-top: 20px;
        padding: 15px 15px 20px;
        h2 {
          margin-bottom: 15px;
        }
        .show-more-comments {
          margin-top: 15px;
          width: 100%;
        }
      }
      .user-profile {
        padding: 15px;
      }
      .post-calendar {
        padding: 15px 15px 20px;
      }
      .most-viewed-posts {
        padding: 15px;
      }
      .poll-container {
        padding: 15px 15px 20px;
      }
      .blog-post {
        width: 100%;

        .blog-post-body {
          padding: 25px 15px 15px;
        }
      }
    }
    .scroll-to-top {
      right: 20px;
      width: 40px;
      height: 30px;
      .material-symbols-outlined {
        font-size: 16px;
      }
    }
    .cookie-consent {
      text-align: center;
      flex-direction: column;
      text-wrap: pretty;
      .btn {
        font-size: 16px;
      }
    }
  }
}
